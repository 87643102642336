

import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { Pagination } from "antd";

import { ColorRing } from 'react-loader-spinner';
import { base_url } from '../Config/config';
import _fetch from '../Config/api';
import dateFormat from "dateformat";
const Activity = () => {
    const [data, setData] = useState()
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSer, setpageSer] = useState(0);

    const handledashboard = async (page = 1, pageSize = 10) => {
        try {
            const skip = (page - 1) * pageSize;
            setpageSer(skip);
            const requestData = {
                limit: pageSize,
                skip: skip,
              


            };

            let response = await _fetch(`${base_url}api/user/activitys`, 'post', requestData);

            if (response.success === true) {
                setData(response);
            } else {
                toast.error(response.message);
            }
        } catch (error) {
            console.log("Error details", error);
        }
    }


    useEffect(() => {
        handledashboard(currentPage, 10);
    }, [currentPage]);
    const handlePaginationChange = (pageOne) => {
        setCurrentPage(pageOne);

    };

    const records = () => {
        if (data && data?.data && data?.data?.length > 0) {
            return data?.data?.map((row, i) => {
                const serialNumber = pageSer + i + 1;
                return (
                    <tr className="table-row" key={i}>
                        <td className="tabel-text w inter">{serialNumber}</td>
                        <td className="tabel-text w inter">{row?.activity}</td>
                        <td className="tabel-text w inter">{row?.discription}</td>
                        <td className="tabel-text w inter">{row?.message}</td>
                 
                        
                        
                        <td>{dateFormat(row.createdAt, "yyyy-mm-dd, hh:mm:ss TT")}</td>
                        <td>{dateFormat(row.updatedAt, "yyyy-mm-dd, hh:mm:ss TT")}</td>


                    </tr>
                );
            });
        } else {
            return (
                <tr>
                    <td colSpan="13">Data Not Found</td>
                </tr>
            );
        }
    };
    return (
        <>
            <div className="section2">

                <div className="table-sec">
                    <div className="transactions">

                        <table className="table sec1">
                            <thead>
                                <tr>
                                    <th scope="col">S.No.</th>
                                    <th scope="col">Activity</th>
                                    <th scope="col">Discription</th>
                                    <th scope="col">Message</th>
                                    <th scope="col">Date</th>
                                    <th scope="col">Update date</th>
                                    
                                    {/* <th scope="col">Closetime</th> */}
                                </tr>
                            </thead>
                            <tbody>
                                {records()}
                            </tbody>
                        </table>
                    </div>
                    <div className="pagination">
                        <Pagination
                            style={{ cursor: "pointer" }}
                            className="mx-auto"
                            current={currentPage || 1}
                            total={data?.count || 0}
                            pageSize={10}
                            onChange={handlePaginationChange}
                            showSizeChanger={false}
                            showQuickJumper={false}
                        />

                    </div>

                </div>
            </div>
        </>
    )
}

export default Activity

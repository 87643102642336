import React, { useEffect, useState } from 'react'
import icon from "../assets/img/icon.svg"
import toast from 'react-hot-toast'
import _fetch from '../Config/api'
import { base_url } from '../Config/config'

const Dashboard = () => {
  const [data, setData] = useState({});
  const [name, setName] = useState({});

  const handledashboard = async () => {
    try {
      let response = await _fetch(`${base_url}api/user/dashboard`, 'get', {});

      if (response.success === true) {
        setData(response?.data?.balance);
        setName(response?.data.user);
      } else {
        toast.error(response.message);
      }
    } catch (error) {
      console.log("Error details", error);
    }
  }

  useEffect(() => {
    handledashboard();
  }, []);

  console.log('data', data);

  return (
    <>
      <div className="row p-5 py-md-5">
        <div className="rounded shadow">
          <div className="d-flex align-items-center justify-content-center">
            <div className="">
              <div className="p-3">
                <h5 className="mb-0">{name?.name || "N/A"}</h5>
              </div>
              <div className="p-3">
                <h6 className="mb-0">{name?.userId || "N/A"}</h6>
              </div>
              <div className="p-3">
                <h5 className="mb-0">{name?.email || "N/A"}</h5>
              </div>
              <div className="p-3">
                <h6>
                  Balance: {data?.walletBalance ? Number(data?.walletBalance).toFixed(4) : "0"} {data?.asset || ""}
                </h6>
              </div>

            </div>
            <div className="vr mt-3"></div>
            <div className="cr">
              <div className="p-3">
                <h6>My Course Package: 0</h6>
              </div>
              <div className="p-3">
                <p className="mb-0">Earning Wallet</p>
              </div>
              <div className="p-3">
                <h6 className="mb-0">0</h6>
              </div>
              <div className="p-3">
                <p className="mb-0">Activation Wallet</p>
              </div>
              <div className="p-3">
                <h6>00:000</h6>
              </div>
            </div>
          </div>
          <hr />
        </div>
      </div>

      <div className="d-flex justify-content-between p-3">
        {/* <img src={icon} alt="icon" /> */}
        {/* <button type='button' className='btn btn-success'>Deposit</button> */}
      </div>
    </>
  )
}

export default Dashboard;

import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { base_url } from '../Config/config';
import _fetch from '../Config/api';
import { ColorRing } from 'react-loader-spinner';
import { useNavigate } from 'react-router-dom';
import dateFormat from "dateformat";
import { Pagination } from "antd";
const OpenPosition = () => {
  const [data, setData] = useState()
  const [currentPage, setCurrentPage] = useState(1);
    const [pageSer, setpageSer] = useState(0); 
  const navigate = useNavigate();
  const handledashboard = async (page = 1, pageSize = 10) => {
    try {
        const skip = (page - 1) * pageSize;
        setpageSer(skip);
        const requestData = {
          limit: pageSize,
          skip: skip,
          type:"FUTURE",
    
        };

      let response = await _fetch(`${base_url}api/user/trades`, 'post', requestData);

      if (response.success === true) {
        setData(response);
      } else {
        toast.error(response.message);
      }
    } catch (error) {
      console.log("Error details", error);
    }
  }



  
  useEffect(() => {
    handledashboard(currentPage, 10);
  }, [currentPage]);
  const handlePaginationChange = (pageOne) => {
    setCurrentPage(pageOne);
   
  };


  const handleStatusChange = (userId, selectedValue) => {
    let url = "";

    switch (selectedValue) {
      case "openview":
        url = `/openview?id=${userId}`;
        break;

      default:
        return;
    }


    navigate(url);
  };

  const records = () => {
    if (data && data?.data && data?.data?.length > 0) {
      return data?.data?.map((row, i) => {
        const serialNumber = pageSer + i + 1;
        return (
          <tr className="table-row" key={i}>
            <td className="tabel-text w inter">{serialNumber}</td>
            <td className="tabel-text w inter">{row?.orderId}</td>
            <td className="tabel-text w inter">{row?.symbol}</td>
            <td className="tabel-text w inter">{row?.status}</td>
            <td className="tabel-text w inter">{row?.price}</td>
            <td className="tabel-text w inter">{row?.orderType}</td>
            <td className="tabel-text w inter">{row?.exchangeName}</td>
            <td className="tabel-text w inter">{row?.leverage}</td>
            <td className="tabel-text w inter">{row?.type}</td>
            <td className="tabel-text w inter">{row?.side}</td>
            <td className="tabel-text w inter">{row?.endPrice}</td>
            <td className="tabel-text w inter">{row?.startPrice}</td>
            <td 
  className={`   ${row?.profitLoss < 0 ? 'text-red' : 'text-green'}`}
>
{row?.profitLoss?.toFixed(4)}
</td>


<td>{dateFormat(row.createdAt, "yyyy-mm-dd, hh:mm:ss TT")}</td>
            {/* <td className="tabel-text w inter">{row?.closeStatus}</td> */}
            <td>
            {row?.closeStatus === true ? (

              <>
              <p style={{color:"red"}}>Closed</p>
              </>
            ) : (
              <>
               <p style={{color:"green"}}>open</p>
              </>

            )}


</td>
            <td className="tabel-text w inter">
              {/* <NavLink to={`/next-page/${row?._id}`}>
              <select className="form-select" aria-label="Default select example">
                <option selected>Open this select menu</option>
                <option value="1">Open</option>
                <option value="2">Close</option>
              </select>
            </NavLink> */}

              {/* <select className="" onChange={(e) => handleStatusChange(row._id, e.target.value)}>
                <option>View</option>
                <option value="openview">Open</option>
                <option value="closeview">Close</option>

              </select> */}
              <button className="btn btn-primary" onClick={() => handleStatusChange(row._id, 'openview')}>View</button>
              {/* <button className="btn btn-primary" onClick={() => handleStatusChange(row._id, 'closeview')}>Close</button>             */}

            </td>


          </tr>
        );
      });
    } else {
      return (
        <tr>
          <td colSpan="13">Data Not Found</td>
        </tr>
      );
    }
  };
  return (
    <>

      <div className="section2">

        <div className="table-sec">
          <div className="transactions">
            <div className='op'>

              <table className="table">
                <thead>
                  <tr>
                    <th scope="col">S.No.</th>
                    <th scope="col">Order Id</th>
                    <th scope="col">Pair</th>
                    <th scope="col">Status</th>
                    <th scope="col">Price</th>
                    <th scope="col">OrderType</th>
                    <th scope="col">Exchange Name</th>
                    <th scope="col">Leverage</th>
                    <th scope="col">Type</th>
                    <th scope="col">Mode</th>
                    <th scope="col">End Price</th>
                    <th scope="col">startPrice</th>
                    <th scope="col">Profit</th>
                  

                    <th scope="col">Date</th>
                    <th scope="col">Order status</th>
                    <th scope="col">View</th>
                  </tr>
                </thead>
                <tbody>
                  {records()}
                </tbody>
              </table>
            </div>
            <div className="pagination">
              <Pagination
                style={{ cursor: "pointer" }}
                className="mx-auto"
                current={currentPage || 1}
                total={data?.count || 0}
                pageSize={10}
                onChange={handlePaginationChange}
                showSizeChanger={false}
                showQuickJumper={false}
              />

            </div>
          </div>

        </div>
      </div>
    </>
  )
}

export default OpenPosition

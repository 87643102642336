import React from 'react'
import { useEffect,useState } from 'react'
import toast from 'react-hot-toast'
import _fetch from '../Config/api'
import { base_url } from '../Config/config'
import dateFormat from "dateformat";
import { Pagination } from "antd";
const Exchagedkey = () => {
    const [data,setData]=useState()
//   const [name,setName]=useState()
const [currentPage, setCurrentPage] = useState(1);
    const [pageSer, setpageSer] = useState(0); 
  const handledashboard = async (page = 1, pageSize = 10) => {
    const skip = (page - 1) * pageSize;
    setpageSer(skip);
    const requestData = {
      limit: pageSize,
      skip: skip

    };
     
     

      try {
          
          let response = await _fetch(`${base_url}api/user/getExchange`, 'get',  {})

    
          if (response.success === true) {
              // toast.success(response.message);
              setData(response)
            //   setName(response.data)
              
          } else {
              toast.error(response.message);
          }
      }
      catch (error) {
          console.log("Error details", error);

      }
  }
  useEffect(() => {
    handledashboard(currentPage, 10);
  }, [currentPage]);
  const handlePaginationChange = (pageOne) => {
    setCurrentPage(pageOne);
   
  };

  const records = () => {
    if (data && data?.data && data?.data?.length > 0) {
        return data?.data?.map((row, i) => {
            return (
                <tr className="table-row" key={i}>
                    <td className="tabel-text w inter">{i + 1}</td>
                    <td className="tabel-text w inter">{row?.userId}</td>
                    <td className="tabel-text w inter">{row?.exchangeName}</td>
                    
                    <td className="tabel-text w inter">{row?.exchangeKeys?.apiKey}</td>
                    <td className="tabel-text w inter">{row?.exchangeKeys?.apiSecret}</td>
                    <td>{dateFormat(row.createdAt, "yyyy-mm-dd, hh:mm:ss TT")}</td>
                </tr>
            );
        });
    } else {
        return (
            <tr>
                <td colSpan="13">Data Not Found</td>
            </tr>
        );
    }
};
  return (
    <>
      <div className="section2">
                <div className="container">
                    <div className="table-sec">
                        <div className="transactions ">
                            
                            <table className="table secs">
                                <thead>
                                    <tr>
                                        <th scope="col">S.No</th>
                                        <th scope="col">UserId</th>
                                        <th scope="col">ExchangeName</th>
                                        
                                        <th scope="col">ApiKey</th>
                                        <th scope="col">ApiSecret </th>
                                      
                                        <th scope="col">Date</th>
                                    </tr>
                                </thead>
                                <tbody>

                                    {records()}
                                </tbody>
                            </table>
                            
                        </div>
                        <div className="pagination">
              <Pagination
                style={{ cursor: "pointer" }}
                className="mx-auto"
                current={currentPage || 1}
                total={data?.count || 0}
                pageSize={10}
                onChange={handlePaginationChange}
                showSizeChanger={false}
                showQuickJumper={false}
              />

            </div>
                    </div>
                </div>
            </div>
    
    </>
  )
}

export default Exchagedkey


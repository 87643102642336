import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import SignUp from './Auth/SignUp';
import Login from './Auth/Login';
import Dashboard from './Dashboard/Dashboard';
import Private from './Routing/Private';
import Public from './Routing/Public';
import { Toaster } from 'react-hot-toast';
import PrivateLayout from './Layouts/PrivateLayout';
import Bindapi from './Blindapi/Bindapi';
import Deposit from './Blindapi/Deposit';
import OpenPositon from "./Trade/OpenPosition"
import ClosePosition from './Trade/ClosePosition';
import Forget from './Auth/Forget';
import Verfication_forget from './Auth/verficationforgetotp'
import Verfication from './Auth/Verfication';
import Exchagedkey from './exchangedkey/Exchagedkey';
import Verfications from './Auth/Verfications';
import Openspotposition from './trader/Openpositions';
import Closespotposition from './trader/Closepositions';
import Openview from './Trade/Openview'
import Openspotview from './trader/Openspotview'
import Activity from './activity/Activity'
const App = () => {
  return (
    <>
    
      <Router>
        <Routes>

          {/* Private routes */}
          <Route element={<Private />}>
            <Route path="/dashboard" element={<PrivateLayout><Dashboard /></PrivateLayout>} />
            <Route path="/bindapi" element={<PrivateLayout><Bindapi /></PrivateLayout>} />
            <Route path="/deposit" element={<PrivateLayout><Deposit /></PrivateLayout>} />
            <Route path="/open_position" element={<PrivateLayout><OpenPositon /></PrivateLayout>} />
            <Route path="/close_position" element={<PrivateLayout><ClosePosition /></PrivateLayout>} />
            <Route path="/exchagedkey" element={<PrivateLayout><Exchagedkey /></PrivateLayout>} />
            <Route path="/closespotposition" element={<PrivateLayout><Closespotposition /></PrivateLayout>} />
            <Route path="/openspotposition" element={<PrivateLayout><Openspotposition /></PrivateLayout>} />
            <Route path='/openview' element={<PrivateLayout><Openview /></PrivateLayout>} />
            <Route path='/openspotview' element={<PrivateLayout><Openspotview /></PrivateLayout>} />
            <Route path='/activity' element={<PrivateLayout><Activity /></PrivateLayout>} />
          </Route>

          {/* Public routes */}
          <Route element={<Public />}>
            <Route path="/" element={<Login />} />
            <Route path="/signup" element={<SignUp />} />
            <Route path="/Verfication" element={<Verfication />} />
            <Route path="/Forget" element={<Forget />} />
            <Route path="/Verfication_forget" element={<Verfication_forget />} />
            <Route path="/Verfications" element={<Verfications />} />
          </Route>
        </Routes>
        <Toaster />
      </Router>

    </>

  );
}

export default App;


